<template>

<div class="main-container password-reset-card">


    <div class="card shadow mt-5">
  <div class="card-body">
    <h5 class="card-title text-center">Change Password</h5>
    <form class="">
      <div class="form-group p-2">
        <label for="password">New Password:</label>
        <input type="password" class="form-control" id="password" placeholder="Enter new password">
      </div>
      <div class="form-group p-2">
        <label for="confirmPassword">Confirm Password:</label>
        <input type="password" class="form-control" id="confirmPassword" placeholder="Confirm password">
      </div>
      <button type="submit" class="btn login-btn text-white mt-3" >Submit</button>
    </form>
  </div>
</div>

  </div>
    

  
</template>

<script>

export default {
    
  }
</script>

<style scoped>

h1{
   font-family: 'Roboto Mono', monospace;
   color: #454545;
}

.login-btn{
  background-color: #008080;
  width:100%;

}

.btn.login-btn:hover {

  transform: scale(1.0);
  box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.5);
  cursor: pointer;
}

.password-reset-card {
  display: flex;
  justify-content: center;
  align-items: center;
 

}

.card {
  width: 500px;
  height: 280px;
 
}



</style>