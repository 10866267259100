
    import { createStore } from 'vuex'

    export default createStore({
    state: {
        token: null,
        email: null,
        password: null,
        loggedIn: false
    
    }
    ,
    mutations: {
        setToken (state, token) {
        
        state.token = token;
        state.loggedIn = true;
        localStorage.setItem('token', token);
    

        },
        setEmail (state, email) {
        state.email = email
        
        },
        setPassword (state, password) {
        state.password = password
        },
        clearUserData (state) {
            state.token = null
            state.email = null
            state.password = null
            state.loggedIn = false;
            localStorage.removeItem('token');
            }
    },
    actions: {
        setToken({ commit }, token) {
        
        
        commit('setToken', token)
        
        },
        setEmail({ commit }, email) {
        commit('setEmail', email)
        },
        setPassword({ commit }, password) {
        commit('setPassword', password)
        },
            clearUserData({ commit }) {
            commit('clearUserData')
            }
    },
    getters: {
        isAuthenticated: state => !!state.token
         

    },

    


    })
