<template>
  <div class="main-container send-card">
    <div class="card shadow mt-5">
      <div class="card-body">
        <form @submit.prevent="submitForm">
          <div class="mb-3">
            <label for="mobileInput" class="form-label">Mobile Number</label>
            <input
              type="tel"
              class="form-control"
              id="mobileInput"
              placeholder="Enter mobile number"
              v-model="mobile"
              required
            />
            <div v-if="mobileError" class="errormessage">{{ mobileError }}</div>
          </div>
          <div class="mb-3">
            <label for="amountInput" class="form-label">Amount</label>
            <div class="input-group">
              <span class="input-group-text">Kshs</span>
              <input
                type="text"
                class="form-control"
                id="amountInput"
                placeholder="Enter amount"
                v-model="amount"
                required
              />
              
            </div>
            <div v-if="amountError" class="errormessage">{{ amountError }}</div>
          </div>
          <button type="submit" class="btn send-btn text-white mt-3">
            Send
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      mobile: "",
      amount: "",
      mobileError: null,
      amountError: null,
    };
  },
  methods: {
    submitForm() {
     
      if (!this.mobile) {
        this.mobileError = "Mobile number is required";
      } else if (isNaN(this.mobile)) {
        this.mobileError = "Mobile must be a number";
      } else if (!/^\d{10,12}$/.test(this.mobile)) {
        this.mobileError = "Mobile number must be between 10 and 12 digits";
      }
    
      if (!this.amount) {
        this.amountError = "Amount is required";
      } else if (!/^\d+$/.test(this.amount)) {
        this.amountError = "Amount must be an integer";
      }else if (parseInt(this.amount) < 10) {
       this.amountError = "Minimum is 10 Kshs";
}

      
      if (!this.mobileError && !this.amountError) {

        const requestBody = {
      mobile: this.mobile,
      amount: this.amount
    };

       axios.post(`${process.env.VUE_APP_BASE_URL}singleSend`, requestBody)
      .then(response => {
        alert(`${this.amount} Kshs airtime sent to ${this.mobile}`);
        this.$nextTick(() => {
          this.$router.go(0);
        });
      })
      .catch(error => {
        alert("Error Sending Airtime");
      });
       
      }
    },
  },
};
</script>

<style scoped>
.send-btn {
  background-color: #008080;
  width: 100%;
}

.btn.send-btn:hover {
  transform: scale(1);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.send-card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  width: 500px;
  height: 280px;
}

.errormessage{
    font-size: small;
  color: red;
  margin-top: 5px;
}
</style>
