<template>
  <div class="main-nav">
    <div class="sidenav ">
      <div class="d-flex" id="wrapper">
        <!-- Sidebar-->
        <div class="border-end side-main side-main-col back" id="sidebar-wrapper" v-if="loggedIn">
          <div class="sidebar-heading "><div class="col-1 "><img src="../assets/images/toppay.png"></div>
          <div class="col center-items "> <span class="systitle">TOP PAY AIRTIME</span></div>
         </div>
          <div class="list-group list-group-flush p-1">
            <template v-if="loggedIn">
              <router-link
                to="/"
                class="
                  list-group-item list-group-item-action list-group-item-light
                  px-5 p-3 side-main-col back  mt-1
                "
                ><font-awesome-icon icon="fa-solid fa-house-user" />  <a href="" class="p-2">Dashboard</a></router-link
              >
              <router-link
                to="/history"
                class="
                  list-group-item list-group-item-action list-group-item-light
                  px-5 p-3 side-main-col back mt-1
                "
                ><font-awesome-icon icon="fa-solid fa-circle-check" />  <a href="" class="p-2">Successful</a></router-link
              >
              <router-link
                to="/failed"
                class="
                  list-group-item list-group-item-action list-group-item-light
                   px-5 p-3 side-main-col back mt-1
                "
                ><font-awesome-icon icon="fa-solid fa-circle-exclamation" /> <a href="" class="p-2">Failed</a></router-link
              >
              <router-link
                to="/excess"
                class="
                  list-group-item list-group-item-action list-group-item-light
                   px-5 p-3 side-main-col back mt-1
                "
                ><font-awesome-icon icon="fa-solid fa-circle-exclamation" /> <a href="" class="p-2">Excess</a></router-link
              >

              <router-link
                to="/send"
                class="
                  list-group-item list-group-item-action list-group-item-light
                   px-5 p-3 side-main-col back mt-1
                "
                ><font-awesome-icon icon="fa-solid fa-circle-exclamation" /> <a href="" class="p-2">Send Airtime</a></router-link
              >
              
              <router-link
                to="/settings"
                class="
                  list-group-item list-group-item-action list-group-item-light
                   px-5 p-3 side-main-col back mt-1
                "
                ><font-awesome-icon icon="fa-solid fa-gear" /> <a href="" class="p-2">Settings</a></router-link
              >
              
            </template>
          </div>
        </div>
        <!-- Page content wrapper-->
        <div id="page-content-wrapper" class="">
          <!-- Top navigation-->
          <nav class="navbar navbar-expand-lg border-bottom side-main-col " v-if="loggedIn">
            <div class="container-fluid">
              <button id="sidebarToggle" class="btn">
                <font-awesome-icon icon="fa-solid fa-bars" class="ham" />
              </button>
              <button
                class="navbar-toggler "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                 <font-awesome-icon icon="fa-solid fa-bars" class="ham" />
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mt-2 mt-lg-0">
                  <template v-if="loggedIn">

                    <li class=" px-1 mx-3 mb-1">
                       <router-link to="/profile" class=""
                        > <a href=""><font-awesome-icon icon="fa-solid fa-user" class="icon mt-1"/></a></router-link
    
                      >
                    </li>
                    <li class="nav-pills bg-warning px-1">
                      <router-link to="/login" class="nav-link" @click.prevent="logOut"
                        >Logout</router-link
                      >
                    </li>
                    
                  </template>
                </ul>
              </div>
            </div>
          </nav>

          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import store from '../store/index'

export default {

  created() {
    window.addEventListener("DOMContentLoaded", (event) => {
    
      const sidebarToggle = document.body.querySelector("#sidebarToggle");
      if (sidebarToggle) {
       
        if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
            document.body.classList.toggle('sb-sidenav-toggled');
        }
        sidebarToggle.addEventListener("click", (event) => {
          event.preventDefault();
          document.body.classList.toggle("sb-sidenav-toggled");
          localStorage.setItem(
            "sb|sidebar-toggle",
            document.body.classList.contains("sb-sidenav-toggled")
          );
        });
      }
    });
  },

   computed: {
    loggedIn() {
      return this.$store.state.loggedIn;
    }
  },

    methods: {

    async logOut() {
      const token = this.$store.state.token
      const email = this.$store.state.email
      const password = this.$store.state.password

      try {
        await axios.post(`${process.env.VUE_APP_BASE_URL}logout`, {
          email,
          password
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        this.$store.commit('clearUserData');
        this.$router.push({ name: 'Login' });
      } catch (error) {
        console.error(error);
      }
    }
  }

  }


</script>

<style scoped>
@import "../assets/styles.css";

.ham {
  height: 25px;
  color:#008080;
}

li,
a {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.nav-link{
  color:#f5f5f5;
}

.nav-link:hover {
  color: #008080;


}

img{
  height:120px;
}

.systitle{
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
    color: white;
   

}

.side-main-col{
  background-color:  #f5f5f5;
  border: none;
  
  

 
}

.font-style{
   font-family: 'Roboto Mono', monospace;
  font-weight: 100;
}

.navs:hover{
  color: #28a745;
}

.nav-pills{
  border-radius: 5px;
}


.back{
  background-color:#008080;
  
}

.list-group-item-action{
  color: white;
  
}

.center-items{
  align-items: center;
  justify-content: center;
  display: flex;
 
}

.icon{
  color: #008080;
  height:32px;
}





</style>
