import { createApp } from 'vue';
import App from './App.vue'
import router from './router'
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faBars} from "@fortawesome/free-solid-svg-icons";
import { faHouseUser} from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck} from "@fortawesome/free-solid-svg-icons";
import { faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import { faGear} from "@fortawesome/free-solid-svg-icons";
import { faUser} from "@fortawesome/free-solid-svg-icons";
import { faChartSimple} from "@fortawesome/free-solid-svg-icons";
import { faSackDollar} from "@fortawesome/free-solid-svg-icons";

import store from './store/index';
import Notifications from '@kyvg/vue3-notification'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';








library.add(faBars,faHouseUser,faCircleCheck,faCircleExclamation,faGear,faUser,faChartSimple,faSackDollar);


createApp(App).use(router).use(Notifications).use(store).provide('router', router).component('EasyDataTable', Vue3EasyDataTable).component("font-awesome-icon", FontAwesomeIcon).mount('#app')
