<template>
  <div class="balance-container">
    <div class="container ">
      <div class="row">
       
        <div class="col">
          <div class="card p-2 m-1 center-items text-white ">
            <div class="card-icon-top-left">
             <font-awesome-icon icon="fa-solid fa-sack-dollar" class="icon" />
            </div>
            <div class="card-body card-content mt-1">
              <h5 class="card-title text-center">Airtime Balance</h5>
              <p class="card-text text-center font-style ">{{balDetails.balance}} Units</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card bg-warning p-2 m-1 center-items text-white">

            <div class="card-icon-top-left">
              <font-awesome-icon icon="fa-solid fa-chart-simple" class="icon" />
            </div>
            <div class="card-body card-content mt-1">
              <h5 class="card-title text-center">Total Purchases</h5>
              <p class="card-text text-center font-style">{{countDetails.count}} People</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";

export default {

  props:['countDetails'],


  data() {
    return {
      balDetails: [],
    };
  },

  created() {
    this.balance();
  },

  methods: {
    async balance() {
      axios
        .get(`${process.env.VUE_APP_BASE_URL}airtime-balance`)
        .then((response) => {
          this.balDetails = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};


</script>

<style scoped>
@import "../assets/styles.css";

.card-content{
    overflow: auto;
    width: 200px; 
    height: 90px;
    
}

.card{
  background-color: #008080;
  position: relative;
  transition: transform 0.2s ease-in-out;
}

.card:hover {
  transform: scale(1.1);
}

.font-style{
  font-weight: 700;
}


.card-icon-top-left {
  position: absolute;
  top: 10px;
  left: 10px;
}

.icon{
  height: 20px;
}

</style>
