<template>

<div class="container profilecont center-items">

     <div class="card text-center mt-4">
  <div class="card-header">

    <img src="../assets/images/toppay.png" class="profile-icon">

  </div>
  <div class="card-body">
    <h5 class="card-title">TOP PAY AIRTIME</h5>
    <p class="card-text">Online dashborad to monitor airtime Purchases</p>
    
  </div>
</div>

</div>
 
</template>

<script>
export default {

}
</script>

<style scoped>

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 300px;
}

.profile-icon {
  width: 180px;
  height: 150px;
  margin: 0 auto;
  display: block;
}


.profilecont{
height: 80vh;

}

.center-items{
    display:flex;
    justify-content: center;
    align-items: center;
}




</style>