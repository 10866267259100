<template >
  <div class="footer-container" v-if="loggedIn">
    <div>
      <footer class="py-4 mt-auto bg-dark">
        <div class="container-fluid px-4 center-items">
          <div class="d-flex align-items-center justify-content-between small">
            <div class="text-white">Copyright &copy; Advanta Africa 2023</div>
            
          </div>
        </div>
      </footer>
    </div>
  </div>

</template>

<script>
export default {
  computed: {
    loggedIn() {
      return this.$store.state.loggedIn;
    }
  },
};
</script>

<style scoped>


</style>