import { createRouter, createWebHistory } from "vue-router";
import multiguard from "vue-router-multiguard";
import Dash from "../views/Dash.vue";
import History from "../views/History.vue";
import SettingsPage from "../views/SettingsPage.vue";
import Failed from "../views/Failed.vue";
import Login from "../views/Login.vue";
import Profile from "../views/Profile.vue";
import Excess from "../views/Excess.vue";
import Send from "../views/Send.vue";
import store from '../store/index';



function isLoggedIn(){

 const token = localStorage.getItem('token');

 
 if(token){
    store.dispatch('setToken', token);
    return true;
 } else {
    return false;
 }
   
}

isLoggedIn();

const routes = [

  {
    path: "/",
    name: "Dashboard",
    component: Dash,
    beforeEnter: (to, from, next) => {
        if(store.getters.isAuthenticated){
        next();
        } else {
           return next({
             name: "Login"
           });
        }
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: (to, from, next) => {
        if(!store.getters.isAuthenticated){
        next();
        } else {
           return next({
             name: "Dashboard"
           });
        }
    }
  },

  {
    path: "/history",
    name: "History",
    component: History,
    beforeEnter: (to, from, next) => {
        if(store.getters.isAuthenticated){
        next();
        } else {
           return next({
             name: "Login"
           });
        }
    }
  },

  {
    path: "/send",
    name: "Send",
    component: Send,
     beforeEnter: (to, from, next) => {
        if(store.getters.isAuthenticated){
        next();
        } else {
           return next({
             name: "Login"
           });
        }
    }
  },

  {
    path: "/settings",
    name: "Settings",
    component: SettingsPage,
     beforeEnter: (to, from, next) => {
        if(store.getters.isAuthenticated){
        next();
        } else {
           return next({
             name: "Login"
           });
        }
    }
  },

  {
    path: "/failed",
    name: "Failed",
    component: Failed,
     beforeEnter: (to, from, next) => {
        if(store.getters.isAuthenticated){
        next();
        } else {
           return next({
             name: "Login"
           });
        }
    }
  },
  {
    path: "/excess",
    name: "Excess",
    component: Excess,
     beforeEnter: (to, from, next) => {
        if(store.getters.isAuthenticated){
        next();
        } else {
           return next({
             name: "Login"
           });
        }
    }
  },

  {
    path: "/profile",
    name: "Profile",
    component: Profile,
     beforeEnter: (to, from, next) => {
        if(store.getters.isAuthenticated){
        next();
        } else {
           return next({
             name: "Login"
           });
        }
    }
  }, 
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
