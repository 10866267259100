<template>
  <div class="card mb-4">
    <h2 class="text-center mt-3">Excess Payment</h2>

    <div class="card-body excess">
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          v-model="searchTerm"
          placeholder="Search by Mobile,ID or Amount"
        />
      </div>
      <table class="table mt-3 table-responsive-sm">
        <thead>
          <tr>
            <th>id</th>
            <th>Mobile</th>
            <th>Amount</th>
            <th>TransID</th>
            <th>Date</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(excess, index) in paginatedexcessDetails"
            v-bind:key="index"
          >
            <td>{{ excess.id }}</td>
            <td>{{ excess.MSISDN }}</td>
            <td>{{ excess.TransAmount }}</td>
            <td>{{ excess.TransID }}</td>
            <td>{{ excess.created_at }}</td>
            <td>
              <span class="badge bg-danger"> {{ excess.Status }} </span>
            </td>

            <th>
              <form
                @submit.prevent="postData(excess.MSISDN, excess.created_at)"
              >
                <button type="submit" class="btn resend btn-warning">
                  Resend
                </button>
              </form>
            </th>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-between">
        <button
          class="btn btn-nav"
          @click="currentPage--"
          :disabled="currentPage === 1"
        >
          Previous
        </button>
        <span
          >{{ currentPage }} of
          {{ Math.ceil(filteredexcessDetails.length / itemsPerPage) }}</span
        >
        <button
          class="btn btn-nav"
          @click="currentPage++"
          :disabled="
            currentPage ===
            Math.ceil(filteredexcessDetails.length / itemsPerPage)
          "
        >
          Next
        </button>
      </div>
    </div>
  </div>

  <!-- <notifications position="top center"/> -->
</template>


<script>
import axios from "axios";
// import { useNotification } from "@kyvg/vue3-notification";
// const notification = useNotification()
export default {
  computed: {
    filteredexcessDetails() {
    const filtered = this.excessDetails.filter(excess => {
      return excess.MSISDN.includes(this.searchTerm) || excess.TransID.includes(this.searchTerm) || excess.TransAmount.toString().includes(this.searchTerm);
    });
    return filtered.slice().reverse();
  },

  paginatedexcessDetails() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return this.filteredexcessDetails.slice(start, end);
  }
  },

  data() {
    return {
      excessDetails: [],
      jdata: null,
      searchTerm: "",
      currentPage: 1,
      itemsPerPage: 15,
    };
  },

  created() {
    this.excessData();
  },

  methods: {
    async excessData() {
      axios
        .get(`${process.env.VUE_APP_BASE_URL}excess`)
        .then((response) => {
          this.excessDetails = response.data;
        })

        .catch((error) => {
          console.log(error);
        });
    },

    async postData(MSISDN, created_at) {
      await axios
        .post(`${process.env.VUE_APP_BASE_URL}query`, {
          MSISDN: MSISDN,
          dateCreated: created_at,
        })

        .then((response) => {
          console.log(response);

          this.$router.go(0);
          this.jdata = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.excess {
  overflow-y: auto;
  max-width: 100%;
}

h2,
th {
  font-family: "Roboto Mono", monospace;
}

.btn-nav {
  background-color: #008080;
  color: white;
}

.btn.btn-nav:hover {
  transform: scale(1);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

th,
td {
  font-size: smaller;
}
.resend {
  padding: 5px 20px;
  font-size: smaller;
  font-weight: bold;
}

.btn.resend:hover {
  color: #008080;
}
</style>
